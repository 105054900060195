import { Dispatch, createContext,useState ,SetStateAction,ReactNode} from "react";
import { ChatMessages } from "../api/models";

export interface UserChatMessageContextInterface {
    chatmessage:ChatMessages,
    ischating : boolean,

    setIsChating:Dispatch<SetStateAction<boolean>>,
    setChatMessage: Dispatch<SetStateAction<ChatMessages>>
}

const defaultChatMessageState = {
    chatmessage:  {
        chatId: "",
        chats: []

    },
    ischating: false,

    setIsChating : (ischating: boolean) =>{},

    setChatMessage: (chatmessage: ChatMessages) =>{}
} as UserChatMessageContextInterface
type ChatMessageProviderProps = {
    children : ReactNode
}
export const ChatMessageContext = createContext(defaultChatMessageState)

export default function ChatMessageProvider ({children}: ChatMessageProviderProps) {
    const [chatmessage, setChatMessage]= useState<ChatMessages>({
        chatId: "",
        chats: []
    
    });
    const [ischating, setIsChating]= useState<boolean>(false);
    return(
        <ChatMessageContext.Provider value={{chatmessage,setChatMessage,ischating,setIsChating}} >
            {children}
        </ChatMessageContext.Provider>
    )
}