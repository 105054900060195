import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useEffect,useState } from 'react';
import { debugLog } from "../../utils/utils";

interface LoadingComponentProps {
  timeout?: number;
  screen: boolean;

}
const LoadingComponent: React.FC<LoadingComponentProps>  = ({timeout = 2000 ,screen = true }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    debugLog("--13")

    const timeoutId = setTimeout(() => { // eslint-disable-line @typescript-eslint/no-unused-vars
      setIsLoading(false);
    }, timeout);
    return () => clearTimeout(timeout);
  });

  return (
    <div className={`${screen ? "h-screen w-screen bg-[url('./assets/img/bg-blured-reduced.jpg')]" : "top-1/2 left-1/2 backdrop-blur-xl"}    fixed z-[1000] `}>
    <Box  sx={{  position: 'fixed',
    zIndex: "50",
    
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: isLoading ? 1 : 0,
    transition: 'opacity 1s ease-in-out' }}>
      <CircularProgress color='inherit' />

    </Box>
       </div>
  );
};

export default LoadingComponent;
