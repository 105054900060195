import {useCallback, useEffect, useState } from "react";

function useTrait<T>(initialValue: T): {
  get: () => T;
  set: (newValue: T) => T;
} {
  const [trait, updateTrait] = useState<T>(initialValue);

  let current: T = trait;

  const get = () => current;

  const set = (newValue: T) => {
    current = newValue;
    updateTrait(newValue);
    return current;
  };

  return {
    get,
    set,
  };
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    debugLog("--17")

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return isTabVisible;
};

export default useIsTabActive;

function debugLog(...values: any[]) {
  if (process.env.REACT_APP_DEBUG === 'true') {
    console.log(...values);
  }
}
export {delay,useTrait,debugLog}
