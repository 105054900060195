// App.tsx

import React, { useEffect,Suspense,lazy, useState } from "react";
import ChatProvider from "../context/chatContext";
import SidebarProvider from "../context/sideBarContext";
import LoginCompProvider from "../context/logincontext";
import ChatMessageProvider from "../context/chatMessages";
import LoadingComponent from './utils/loading';
import { debugLog } from "../utils/utils";
// import SidebarComponent from "../components/sidemenu/SideBar";
// import AlertBox from "./utils/AlertBox";
// import Navbar from "../components/navbar/Navbar";
// import ChangeLanguage from "../components/utils/changelanguage";
const ChatArea = lazy(() => (import('./ChatArea')));
const SidebarComponent = lazy(() => (import('../components/sidemenu/SideBar')));
const AlertBox = lazy(() => (import('./utils/AlertBox')));
const Navbar = lazy(() => (import('../components/navbar/Navbar')));
const ChangeLanguage = lazy(() => (import('../components/utils/changelanguage')));

// const ChatArea = lazy(() => import('./ChatArea').then(module => {
//   return { default: module.default };
// }));
const preloadImages = (imagePaths: string[]): Promise<void[]> => {
  return Promise.all(
    imagePaths.map((path) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = path;
        img.onload = () => {
          console.log(`Image loaded: ${path}`);
          resolve();
        };
        img.onerror = (error) => {
          console.error(`Error loading image: ${path}`, error);
          reject(new Error(`Failed to load image: ${path}`));
        };
      });
    })
  );
};
const Home: React.FC = () => {

  const [imagesLoaded, setImagesLoaded] = useState(false);


  useEffect(() => {
    const imagePaths = [
      '/img/bg-reduced.webp',
      '/img/logincover2-reduced.webp',
      '/img/bg-blured-reduced.jpg'
      // Add all your image paths here
    ];

    preloadImages(imagePaths)
      .then(() => setImagesLoaded(true))
      .catch((error) => console.error('Error loading images:', error));
    debugLog("--2")
    document.title = "Hami Psychiatrist";
  }, []);
  if (!imagesLoaded){
    return(<LoadingComponent screen={true} timeout={10000000} ></LoadingComponent>)
  }
  return (
    <ChatProvider>
      <SidebarProvider>
        <LoginCompProvider>
          <ChatMessageProvider>
            {/* layer one of stack */}
                <Suspense fallback={<LoadingComponent screen={true} />}>

            <div className="h-screen w-screen blur-[0px] -z-10 fixed bg-[url('./assets/img/bg-reduced.webp')] bg-[length:1400px_1100px] bg-image bg-no-repeat sm:bg-cover bg-center">
              <div className="h-full w-full bg-black opacity-5"></div>
            
            </div>
            <ChangeLanguage ClassName="z-[50]" />

            <Navbar ClassName="z-50 " />
            <SidebarComponent ClassName="z-[100]" />
            <AlertBox />
            <div className="h-screen w-screen fixed z-[40] ">
              <div
                className={`flex flex-col  h-full w-full  flex-wrap overflow-hidden  `}
              >
                {/* <div className="w-full   h-[20%] min-[500px]:h-[10%]  z-0 flex items-center justify-center   "> */}
                {/* navbar */}
                {/* </div> */}

                {/* col2 */}

                <div className="flex-grow h-[100%] w-full   ">
                  <ChatArea />
                  {/* <LazyComponent/> */}
                </div>
              </div>
            </div>
                </Suspense>
          </ChatMessageProvider>
        </LoginCompProvider>
      </SidebarProvider>
    </ChatProvider>
  );
};

export default Home;

// function delayForDemo(promise:any) {
//   return new Promise(resolve => {
//     setTimeout(resolve, 2000);
//   }).then(() => promise);
// }