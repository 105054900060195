import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { useLocation, useNavigationType,createRoutesFromChildren,matchRoutes } from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://3912acdf74c5b5a95cc09c3c9fa60bb2@sentry.kheradmen.com/5",
  enableTracing: true,

	// The sampling rate for profiling is relative to TracesSampleRate:
	profilesSampleRate: 1.0,
  integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: ['https://hamichatapi.kheradmen.com'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://hamichatapi.kheradmen.com"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
