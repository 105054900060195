

import React, { useEffect } from "react";

import Home from "./components/home";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App: React.FC = () => {
  useEffect(() => {
    document.title = "Hami Psychiatrist";
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Home />} />
        

      </Routes>
    </Router>
  );
};

export default App;
