import { Dispatch, createContext,useState ,SetStateAction,ReactNode, useEffect} from "react";
import {  Chat} from '../api/models';
import { useSearchParams } from "react-router-dom";
import { debugLog } from "../utils/utils";

export type Language = "en" | "fa";

export type Error = {
    error: string,
    errortype: string,
    isvisible: boolean
};


export interface UserChatContextInterface {
    chat:Chat,
    language: Language,
    isoverlay: boolean,
    overlayindex: number,
    balance: number,
    error: Error,
    ispaymentmode: boolean,
    appointmentlink: string
    appointmentpaymentamount: number
    iswelcome: boolean
    chatInputEnabled: boolean
    agent: string
    setAgent: Dispatch<SetStateAction<string>>

    setChatInputEnabled:Dispatch<SetStateAction<boolean>>
    setAppointmentpaymentamount: Dispatch<SetStateAction<number>>
    setIswelcome: Dispatch<SetStateAction<boolean>>
    isloading: boolean
    setIsloading: Dispatch<SetStateAction<boolean>>

    setAppointmentlink: Dispatch<SetStateAction<string>>

    setIspaymentmode: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<Error>>
    setOverlayindex: Dispatch<SetStateAction<number>>
    setBalance: Dispatch<SetStateAction<number>>
    setIsoverlay: Dispatch<SetStateAction<boolean>>
    setLanguage: Dispatch<SetStateAction<Language>>
    setChat: Dispatch<SetStateAction<Chat>>
}

const defaultChatState = {
    chat:  {
        name: "",
        id: "",
        created_at: 0 

    },
    language: localStorage.getItem("lang"),
    isoverlay: false,
    iswelcome: false,

    balance: 0 ,
    error: {
        isvisible: false,
        error:"",
        errortype: ""
    },
    appointmentlink: "",
    appointmentpaymentamount: 0 ,
    chatInputEnabled: false,
    isloading:false,
    agent: "",
    setAgent:  (agent: string) =>{

    },
    setIsloading: (iswelcome: boolean) =>{},
    setChatInputEnabled:(chatInputEnabled: boolean) =>{},

    setAppointmentlink:(appointmentlink: string) =>{

    },
    setAppointmentpaymentamount:(appointmentpaymentamount: number) =>{

    },
    setBalance:(balance: number) =>{

    },
    setLanguage: (language: Language) =>{

    },
    overlayindex: 1,
    ispaymentmode: false,
    setIspaymentmode: (ispaymentmode: boolean) =>{},
    setChat: (chat: Chat) =>{},
    setIsoverlay: (isoverlay: boolean) =>{},
    setIswelcome: (iswelcome: boolean) =>{},

    setOverlayindex:(overlayindex: number) =>{},
    setError:(error: Error) =>{},
} as UserChatContextInterface
type ChatProviderProps = {
    children : ReactNode
}

export const ChatContext = createContext(defaultChatState)

export default function ChatProvider ({children}: ChatProviderProps) {
  debugLog("initcomponent--2")

    const [searchParams] = useSearchParams();
    let chtid = searchParams.get("chatid")
    let chatnow: Chat 
    if (chtid){

        chatnow = { name: "",
        id: chtid,
        created_at: 0,
        agent: ""
    }

    
    
    }else{
        chatnow = { name: "",
        id: "",
        created_at: 0 ,
        agent: ""}

    }

    const [chat, setChat]= useState<Chat>(chatnow);
    const [isoverlay, setIsoverlay]= useState<boolean>(false);
    const [iswelcome, setIswelcome]= useState<boolean>(true);
    const [isloading, setIsloading]= useState<boolean>(true);


    const [ispaymentmode, setIspaymentmode]= useState<boolean>(false);

    const [overlayindex, setOverlayindex]= useState<number>(1);
    const [balance, setBalance]= useState<number>(0);
    const [appointmentlink, setAppointmentlink]= useState<string>("");
    const [appointmentpaymentamount, setAppointmentpaymentamount]= useState<number>(0);
    const [chatInputEnabled, setChatInputEnabled]= useState<boolean>(false);

    const [error,setError] = useState<Error>({
        error: "some error",
        isvisible: false,
        errortype: "error"
    });



    let savedlang = localStorage.getItem("lang")
    let effectlang : Language
    if (savedlang && (savedlang === "en" ||savedlang === "fa")){
        effectlang = savedlang 
        
    }else{
        effectlang = "fa"
    }
    const [language, setLanguage]= useState<Language>(effectlang);
    const [agent,setAgent]= useState<string>("");

    useEffect(() => {
    debugLog("--16")

        // additional code here
        localStorage.setItem("lang",language)
        
        
      }, [language])
    return(
        <ChatContext.Provider value={{chat,setChat,language,setLanguage,isoverlay,setIsoverlay,
        overlayindex,setOverlayindex,balance,setBalance,error,setError,ispaymentmode, setIspaymentmode,
        appointmentlink,setAppointmentlink,appointmentpaymentamount,setAppointmentpaymentamount,chatInputEnabled,
         setChatInputEnabled,iswelcome, setIswelcome,isloading, setIsloading,agent,setAgent}} >
            {children}
        </ChatContext.Provider>
    )
}