import { Dispatch, createContext,useState ,SetStateAction,ReactNode, useContext} from "react";
import { Chat } from "../api/models";
import { useSearchParams } from "react-router-dom";
import { ChatContext } from "./chatContext";

 type Sidebar = {

    showSidebar: boolean;
    chats: Chat[];
    selectedChat: number;
    selectedChatId: string;


};
export interface SideBarContextInterface {
    sidebar:Sidebar,
    

    setSidebar: Dispatch<SetStateAction<Sidebar>>
}

const defaultSidebarState = {
    sidebar:{
        showSidebar : false
    },
    setSidebar: (sidebar: Sidebar) =>{}
}  as SideBarContextInterface

type SidebarProviderProps = {
    children : ReactNode
}
export const SidebarContext = createContext(defaultSidebarState)

export default function SidebarProvider ({children}: SidebarProviderProps) {
    const [searchParams] = useSearchParams();
  const {setIswelcome } =  useContext(ChatContext)

    let chtid = searchParams.get("chatid")
    let chatnow: Chat 
    if (chtid){
        setIswelcome(false)
        chatnow = { name: "",
        id: chtid,
        created_at: 0,
        agent: "" }

    
    
    }else{
        chatnow = { name: "",
        id: "",
        created_at: 0 ,
        agent: ""}

    }

    const [sidebar, setSidebar]= useState<Sidebar>({
        showSidebar: false,
        chats: [],
        selectedChat: -1,
        selectedChatId: chatnow.id
    });
    return(
        <SidebarContext.Provider value={{sidebar,setSidebar}} >
            {children}
        </SidebarContext.Provider>
    )
}