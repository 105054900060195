import { Dispatch, createContext,useState ,SetStateAction,ReactNode} from "react";


export type LoginComp = {

    showLoginComp: boolean;
    showIsCode: boolean;
    Iserror: boolean;
    error: string;


};
export interface LoginCompContextInterface {
    logincomp:LoginComp,
    

    setLoginComp: Dispatch<SetStateAction<LoginComp>>
}

const defaultLoginCompState = {
    logincomp:{
        showLoginComp : false,
        showIsCode: false,
    },
    setLoginComp: (logincomp: LoginComp) =>{}
}  as LoginCompContextInterface

type LoginCompProviderProps = {
    children : ReactNode
}
export const LoginCompContext = createContext(defaultLoginCompState)

export default function LoginCompProvider ({children}: LoginCompProviderProps) {
    const [logincomp, setLoginComp]= useState<LoginComp>({
        showLoginComp: false,
        showIsCode: false,
        Iserror: false,
        error: ""
    });
    return(
        <LoginCompContext.Provider value={{logincomp,setLoginComp}} >
            {children}
        </LoginCompContext.Provider>
    )
}